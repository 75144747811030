import { lazy, Suspense } from "react";
import styled, { css } from "styled-components";
import { firstUp } from "../utilities/functions";
import isPropValid from "@emotion/is-prop-valid";
import Loading from "./Loading";

const icons = {
  Search: lazy(() => import("./icons/Search")),
  ArrowRight: lazy(() => import("./icons/ArrowRight")),
  ArrowLeft: lazy(() => import("./icons/ArrowLeft")),
  Bell: lazy(() => import("./icons/Bell")),
  Snapchat: lazy(() => import("./icons/Snapchat")),
  Facebook: lazy(() => import("./icons/Facebook")),
  Instagram: lazy(() => import("./icons/Instagram")),
  Linkedin: lazy(() => import("./icons/Linkedin")),
  Twitter: lazy(() => import("./icons/Twitter")),
  Tiktok: lazy(() => import("./icons/Tiktok")),
  Pen: lazy(() => import("./icons/Pen")),
  CheckCircle: lazy(() => import("./icons/CheckCircle")),
  Cloud: lazy(() => import("./icons/Cloud")),
  Donut: lazy(() => import("./icons/Donut")),
  Pdf: lazy(() => import("./icons/Pdf")),
  CircleArrowRight: lazy(() => import("./icons/CircleArrowRight")),
  CircleArrowLeft: lazy(() => import("./icons/CircleArrowLeft")),
  Trash: lazy(() => import("./icons/Trash")),
  Trash2: lazy(() => import("./icons/Trash2")),
  EllipsisVertical: lazy(() => import("./icons/EllipsisVertical")),
  Sliders: lazy(() => import("./icons/Sliders")),
  CheckCircleSolid: lazy(() => import("./icons/CheckCircleSolid")),
  AngleLeft: lazy(() => import("./icons/AngleLeft")),
  AngleDown: lazy(() => import("./icons/AngleDown")),
  AngleUp: lazy(() => import("./icons/AngleUp")),
  Xmark: lazy(() => import("./icons/Xmark")),
  Mouse: lazy(() => import("./icons/Mouse")),
  Play: lazy(() => import("./icons/Play")),
  Download: lazy(() => import("./icons/Download")),
  Copy: lazy(() => import("./icons/Copy")),
  Copy2: lazy(() => import("./icons/Copy2")),
  Gear: lazy(() => import("./icons/Gear")),
  Gears: lazy(() => import("./icons/Gears")),
  Shield: lazy(() => import("./icons/Shield")),
  Globe: lazy(() => import("./icons/Globe")),
  ClipboardList: lazy(() => import("./icons/ClipboardList")),
  LightBulb: lazy(() => import("./icons/LightBulb")),
  UniversalAccess: lazy(() => import("./icons/UniversalAccess")),
  CloudSun: lazy(() => import("./icons/CloudSun")),
  Seedling: lazy(() => import("./icons/Seedling")),
  PeopleGroup: lazy(() => import("./icons/PeopleGroup")),
  TableColumns: lazy(() => import("./icons/TableColumns")),
  LayerGroup: lazy(() => import("./icons/LayerGroup")),
  Building: lazy(() => import("./icons/Building")),
  User: lazy(() => import("./icons/User")),
  Mail: lazy(() => import("./icons/Mail")),
  Key: lazy(() => import("./icons/Key")),
  Eye: lazy(() => import("./icons/Eye")),
  EyeSlash: lazy(() => import("./icons/EyeSlash")),
  PowerOff: lazy(() => import("./icons/PowerOff")),
  Dashboard: lazy(() => import("./icons/Dashboard")),
  Schema: lazy(() => import("./icons/Schema")),
  Products: lazy(() => import("./icons/Products")),
  UserCadre: lazy(() => import("./icons/UserCadre")),
  Wallet: lazy(() => import("./icons/Wallet")),
  Lock: lazy(() => import("./icons/Lock")),
  Plus: lazy(() => import("./icons/Plus")),
  DoubleCheck: lazy(() => import("./icons/DoubleCheck")),
  MagnifyingGlass: lazy(() => import("./icons/MagnifyingGlass")),
  Check: lazy(() => import("./icons/Check")),
  OpenLink: lazy(() => import("./icons/OpenLink")),
  Warning: lazy(() => import("./icons/Warning")),
  Print: lazy(() => import("./icons/Print")),
  Info: lazy(() => import("./icons/Info")),
  Tags: lazy(() => import("./icons/Tags")),
  Certificate: lazy(() => import("./icons/Certificate")),
  Map: lazy(() => import("./icons/Map")),
  Circle: lazy(() => import("./icons/Circle")),
  Minus: lazy(() => import("./icons/Minus")),
  CircleUp: lazy(() => import("./icons/CircleUp")),
  CircleDown: lazy(() => import("./icons/CircleDown")),
  ExportFile: lazy(() => import("./icons/ExportFile")),
  //added by ghewa
  ArrowDown: lazy(() => import("./icons/ArrowDown")),
  ArrowDownAndUp: lazy(() => import("./icons/ArrowDownAndUp")),
  CreateForm: lazy(() => import("./icons/CreateForm")),
  FormEasyIcon: lazy(() => import("./icons/FormEasyIcon")),
  More: lazy(() => import("./icons/More")),
  Filter: lazy(() => import("./icons/Filter")),
  Sort: lazy(() => import("./icons/Sort")),
  Export: lazy(() => import("./icons/Export")),
  File: lazy(() => import("./icons/File")),
  File2: lazy(() => import("./icons/File2")),
  ArrowSquareOut: lazy(() => import("./icons/ArrowSquareOut")),
  WarningOctagon: lazy(() => import("./icons/WarningOctagon")),
  Security: lazy(() => import("./icons/Security")),
  Customize: lazy(() => import("./icons/Customize")),
  Notification: lazy(() => import("./icons/Notification")),
  User2: lazy(() => import("./icons/User2")),
  Logout: lazy(() => import("./icons/Logout")),
  Code: lazy(() => import("./icons/Code")),
  Xsign: lazy(() => import("./icons/Xsign")),
};

const Wrap = styled.span
  .withConfig({
    shouldForwardProp: (prop) => isPropValid(prop),
  })
  .attrs((p) => {
    return {
      hover: p.hover && typeof p.hover === "boolean" ? "gray200" : p.hover,
    };
  })`
  line-height: 0;
  transition: all ${(p) =>
    p.circle ? ".05" : ".1"}s cubic-bezier(0.4, 0, 1, 1);
  ${(p) => {
    const dimension = p.circle || p.rounded ? "8px" : "16px";
    const bgc = p.rounded ? "transparent" : p.theme.color[p.hover] + "cc";
    let outlineWidth = "8px";
    if (p.circle) outlineWidth = "4px";
    else if (p.rounded) outlineWidth = "0px";
    let activeOutlineWidth = p.rounded ? "0px" : "8px";

    return (
      p.hover &&
      css`
        cursor: pointer;
        width: calc(${dimension} + ${p.w}px);
        height: calc(${dimension} + ${p.w}px);
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        outline: ${p.theme.color[p.hover]}99 0px solid;
        transform: scale(1);
        &:hover {
          background-color: ${bgc};
          outline: ${p.theme.color[p.hover] + "cc"} ${outlineWidth} solid;
          transform: scale(0.96);
        }
        &:active {
          background-color: ${p.theme.color[p.hover]}99;
          outline: ${p.theme.color[p.hover]}99 ${activeOutlineWidth} solid;
          transform: scale(0.85);
        }
      `
    );
  }}
  svg {
    fill: ${(props) =>
      props.c && props.c.startsWith("#")
        ? props.c
        : props.theme.color[props.c]};
    width: ${(p) => p.w}px;
  }
  ${(p) =>
    p.circle &&
    css`
      width: ${Number(p.w) + 14}px;
      height: ${Number(p.w) + 14}px;
      display: inline-block;
      border-radius: 200px;
      background-color: ${(props) =>
        props.c && props.c.startsWith("#")
          ? props.c
          : props.theme.color[props.c]};
      position: relative;
      svg {
        fill: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: ${(p) => p.w - p.w * 0.26}px;
      }
    `}
    ${(p) =>
      p.rounded &&
      css`
        width: ${Number(p.w) + 14}px;
        height: ${Number(p.w) + 14}px;
        display: inline-block;
        border-radius: 200px;
        border: ${(props) =>
            props.c && props.c.startsWith("#")
              ? props.c
              : props.theme.color[props.c]}
          1px solid;
        position: relative;
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: ${(p) => p.w - p.w * 0.2}px;
        }
      `}
  ${(p) =>
    p.disabled &&
    css`
      cursor: default;
      pointer-events: none;
      background-color: red;
      width: 100px;
      svg {
        fill: ${p.theme.color.gray400};
        width: 100%;
      }
    `}
`;

function Icon({ name, w = "30", c = "dark", hover, ...rest }) {
  if (!icons[firstUp(name || "")]) return;
  const Component = icons[firstUp(name)];
  return (
    <Suspense fallback={<Loading size={16} />}>
      <Wrap {...{ w, c, hover, ...rest }}>
        <Component />
      </Wrap>
    </Suspense>
  );
}

export default Icon;

// const Wrap = styled.span`
//   line-height: 0;
//   transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
//   ${(props) =>
//     props.hover &&
//     css`
//       cursor: pointer;
//       width: calc(16px + ${(props) => props.w}px);
//       height: calc(16px + ${(props) => props.w}px);
//       border-radius: 50%;
//       display: inline-flex;
//       align-items: center;
//       justify-content: center;
//       outline: transparent 0px solid;
//       &:hover {
//         /* fill: ${props.theme.color[props.hover]}; */
//         background-color: ${props.theme.color.gray200};
//         outline: ${props.theme.color.gray200} 6px solid;
//       }
//       &:active {
//         background-color: ${props.theme.color.gray200};
//         transform: scale(0.99);
//       }
//     `}
//   ${(p) =>
//     p.disabled &&
//     css`
//       pointer-events: none;
//       cursor: not-allowed;
//       svg {
//         fill: ${p.theme.color.gray500} !important;
//       }
//     `}
//   svg {
//     fill: ${(props) => props.c && props.c.startsWith('#') ? props.c : props.theme.color[props.c]};
//     width: ${(props) => props.w}px;
//   }
//   ${(props) =>
//     props.circle &&
//     css`
//       width: ${props.w + 22}px;
//       height: ${props.w + 22}px;
//       display: inline-block;
//       border-radius: 200px;
//       background-color: ${(props) => props.c && props.c.startsWith('#') ? props.c : props.theme.color[props.c]};
//       position: relative;
//       svg {
//         fill: white;
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//       }
//     `}
// `;

// function Icon({ name, w = "30", c = "black", hover, ...rest }) {
//   if (!icons[firstUp(name || "")]) return;
//   const Component = icons[firstUp(name)];
//   return (
//     <Wrap role="figure" {...{ w, c, hover, ...rest }}>
//       <Suspense fallback={<dfn title={name + " icon"}>{name} icon</dfn>}>
//         <Component />
//       </Suspense>
//     </Wrap>
//   );
// }

// export default Icon;
