import Layout from "layout/Layout";
import React from "react";
import { Box, Flex, Icon, Type } from "ui";
import SettingsWithBackground from "../../assests/SettingsWithBackgound.svg";
import styled from "styled-components";
import { useSubmissionForm } from "views/submitionForm/provider";
import slack1 from "../../assests/slack 1.svg";
import airbnb from "../../assests/airbnb-logo 1.svg";
import integration from "../../assests/integration-hubspot 1.svg";
import integrationGoogle from "../../assests/integration-google-sheets 1.svg";
import vector from "../../assests/Vector.svg";
import analystic from "../../assests/google-analytics 1.svg";
import "./integration.css";
const StyledFlex = styled(Flex)`
  padding: 0px 8px;
  gap: 3px;
  color: ${(props) => props.theme.color.black};
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 12px;
  background-color: ${(props) => props.theme.color.gray300};
  border-radius: 20px;
  font-weight: 400;
`;
function Integrations() {
  const { selectedForm } = useSubmissionForm();
  const integrations = [
    { img: slack1, title: "Google sheets" },
    { img: airbnb, title: "Google sheets" },
    { img: integration, title: "Google sheets" },
    { img: integrationGoogle, title: "Google sheets" },
    { img: vector, title: "Google sheets" },
    { img: analystic, title: "Google sheets" },
    { img: slack1, title: "Google sheets" },
    { img: airbnb, title: "Google sheets" },
    { img: integration, title: "Google sheets" },
  ];
  return (
    <Layout>
      {/* <Flex
        gap="10"
        ai="center"
        jc="flex-start"
        sx={{ p: "0px 0px", mb: "20px" }}
      >
        <img src={SettingsWithBackground} alt="settings" width={"95px"} />
        <Box>
          <StyledFlex>
            ID {selectedForm?.uniqueId}
            <Icon name={"Copy2"} w="16" />
          </StyledFlex>
        </Box>
      </Flex> */}
      <Type var="h4" sx={{ fw: "700", py: "20px", fs: "28px" }}>
        Integrations
      </Type>
      <Type
        var="subtitle"
        sx={{ c: "type", fs: "24px", ff: "secondary", m: "0px" }}
      >
        This page allows you to integrate your form with third-party services.
        You can find information like your API key and provide a linking URL on
        the web.
      </Type>
      <div class="centered">
        <div class="cards">
          {integrations.map((integ) => (
            <div class="card">
              <img  src={integ.img} alt="" />
              <p>{integ.title}</p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}

export default Integrations;
