import React, { useState } from "react";
import { Box } from "ui";
import Footer from "./footer/Footer";
import HeaderLayout from "./header/HeaderLayout";
import slide1 from "../../assests/slide1.svg";
import slide2 from "../../assests/slide2.svg";
import slide3 from "../../assests/slide3.svg";
import slide4 from "../../assests/slide4.svg";
import slide5 from "../../assests/slide5.svg";
import slide6 from "../../assests/slide6.svg";

import open from "../../assests/open.svg";
import close from "../../assests/close.svg";

import img1 from "../../assests/1.CONFIGURE.svg";
import img2 from "../../assests/2.Copy.svg";
import img3 from "../../assests/3.Inbox.svg";
import img11 from "../../assests/EndPoint.svg";
import img111 from "../../assests/endpoint11.svg";
import img12 from "../../assests/collect-sub.svg";
import img122 from "../../assests/submossion1.svg";
import img13 from "../../assests/uploadFiles.svg";
import img133 from "../../assests/upload1.svg";
import img14 from "../../assests/response.svg";
import img144 from "../../assests/autoResponse1.svg";
import img15 from "../../assests/reciveSend.svg";
import img155 from "../../assests/recieve1.svg";
import img16 from "../../assests/export.svg";
import img166 from "../../assests/export1.svg";
import "./homepage.css";
import { useNavigate } from "react-router-dom";
// import { useAuth } from "services/auth";

function HomePage() {
  const [click, setClick] = useState(false);

  const [lid, setLid] = useState("");
  const openDetail = (e) => {
    setClick(true);
    setLid(e.target.id);
    console.log(lid, "lid");
  };
  const [id, setId] = useState("1");
  let navigate = useNavigate();
  // let auth = useAuth();

  const getStarted = () => {
    // auth.signout();
    navigate("/login");
  };

  return (
    <Box sx={{of: 'hidden', w: '100vw'}}>
      <HeaderLayout />
      <Box sx={{ m: "0 auto" }}>
        <div className="firstDiv">
          <p className="firstDiv-P">
            More than 200+ companies trusted us worldwide
          </p>
          <div class="slider">
            <div class="slide-track">
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/1.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/1.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/1.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
              <div class="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png"
                  height="50"
                  width="125"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="secondDiv">
          <p className="secondDiv-div-P">Web forms in 3 easy steps</p>
          <p className="secondDiv-div-P2">3 simple steps to work</p>
          <button className="secondDiv-div-btn" onClick={getStarted}>
            Get started
          </button>
          <div className="secondDiv-div-row">
            <div className="secondDiv-div-row-div">
              <img className="secondDiv-div-row-div-img" src={img1} alt="" />
              <p className="secondDiv-div-row-div-P">1.CONFIGURE</p>
              <p className="secondDiv-div-row-div-P2">
                Our automated tool lets you configure and personalize forms
                with the click of a button.
              </p>
            </div>

            <div className="secondDiv-div-row-div">
              <img className="secondDiv-div-row-div-img" src={img2} alt="" />
              <p className="secondDiv-div-row-div-P">2.COPY</p>
              <p className="secondDiv-div-row-div-P2">
                Once your form is setup, simply copy and paste the endpoint code
                to your website.
              </p>
            </div>
            <div className="secondDiv-div-row-div">
              <img className="secondDiv-div-row-div-img" src={img3} alt="" />
              <p className="secondDiv-div-row-div-P">3.CHECK YOUR INBOX</p>
              <p className="secondDiv-div-row-div-P2">
                Your form is ready to go. Messages from your website will be
                sent to your email.
              </p>
            </div>
          </div>
        </div>
        <div className="thirdDiv">
          <p className="thirdDiv-P">
            Everything you need to make your form work.
          </p>
          <p className="thirdDiv-P2">
            Make your form work in seconds without relying on dependencies,
            special libraries etc.
          </p>
          <div className="thirdDiv-nav">
            <button
              id="1"
              onClick={(e) => setId(e.target.id)}
              className={
                id === "1" ? "thirdDiv-nav-button2" : "thirdDiv-nav-button"
              }
            >
              Endpoint
            </button>
            <button
              id="2"
              onClick={(e) => setId(e.target.id)}
              className={
                id === "2" ? "thirdDiv-nav-button2" : "thirdDiv-nav-button"
              }
            >
              Collect submission
            </button>
            <button
              id="3"
              onClick={(e) => setId(e.target.id)}
              className={
                id === "3" ? "thirdDiv-nav-button2" : "thirdDiv-nav-button"
              }
            >
              Upload files
            </button>
            <button
              id="4"
              onClick={(e) => setId(e.target.id)}
              className={
                id === "4" ? "thirdDiv-nav-button2" : "thirdDiv-nav-button"
              }
            >
              Auto Responses
            </button>
            <button
              id="5"
              onClick={(e) => setId(e.target.id)}
              className={
                id === "5" ? "thirdDiv-nav-button2" : "thirdDiv-nav-button"
              }
            >
              Recive or send
            </button>
            <button
              id="6"
              onClick={(e) => setId(e.target.id)}
              className={
                id === "6" ? "thirdDiv-nav-button2" : "thirdDiv-nav-button"
              }
            >
              Export
            </button>
          </div>
          {id === "1" ? (
            <div className="thirdDiv-img">
              <img src={img11} alt="" className="thirdDiv-img-web" />
              <img src={img111} alt="" className="thirdDiv-img-mob" />
              <button className="thirdDiv-img-button" onClick={getStarted}>
                Get started
              </button>
            </div>
          ) : id === "2" ? (
            <div className="thirdDiv-img">
              <img src={img12} alt="" className="thirdDiv-img-web" />
              <img src={img122} alt="" className="thirdDiv-img-mob" />
              <button className="thirdDiv-img-button1" onClick={getStarted}>
                Get started
              </button>
            </div>
          ) : id === "3" ? (
            <div className="thirdDiv-img">
              <img src={img13} alt="" className="thirdDiv-img-web" />
              <img src={img133} alt="" className="thirdDiv-img-mob" />
              <button className="thirdDiv-img-button2" onClick={getStarted}>
                Get started
              </button>
            </div>
          ) : id === "4" ? (
            <div className="thirdDiv-img">
              <img src={img14} alt="" className="thirdDiv-img-web" />
              <img src={img144} alt="" className="thirdDiv-img-mob" />
              <button className="thirdDiv-img-button3" onClick={getStarted}>
                Get started
              </button>
            </div>
          ) : id === "5" ? (
            <div className="thirdDiv-img">
              <img src={img15} alt="" className="thirdDiv-img-web" />
              <img src={img155} alt="" className="thirdDiv-img-mob" />
              <button className="thirdDiv-img-button4" onClick={getStarted}>
                Get started
              </button>
            </div>
          ) : id === "6" ? (
            <div className="thirdDiv-img">
              <img src={img16} alt="" className="thirdDiv-img-web" />
              <img src={img166} alt="" className="thirdDiv-img-mob" />
              <button className="thirdDiv-img-button5" onClick={getStarted}>
                Get started
              </button>
            </div>
          ) : undefined}
        </div>
        <div className="forthDiv">
          <p className="forthDiv-P">
            Built and designed to be fast, reliable and easy to use.
          </p>
          <p className="forthDiv-P2">
            Get started by creating an account and setting up your to collect
            submissions in just 2 minutes.
          </p>
          <button className="forthDiv-button" onClick={getStarted}>
            Get started
          </button>
        </div>
        <div className="fifthDiv">
          <button className="fifthDiv-btn">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6769 6.64629V17.3537C14.6769 19.7048 17.6251 20.868 19.2465 19.2465C20.868 17.6251 19.7048 14.6769 17.3537 14.6769H6.64629C4.29519 14.6769 3.13204 17.6251 4.75346 19.2465C6.37489 20.868 9.32314 19.7048 9.32314 17.3537V6.64629C9.32314 4.29519 6.37489 3.13204 4.75346 4.75346C3.13204 6.37489 4.29519 9.32314 6.64629 9.32314H17.3537C19.7048 9.32314 20.868 6.37489 19.2465 4.75346C17.6251 3.13204 14.6769 4.29519 14.6769 6.64629Z"
                stroke="#254F1A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Integrations
          </button>
          <p className="fifthDiv-P">Send Submissions to 3rd Parties.</p>
          <p className="fifthDiv-P2" style={{marginBottom: 40}}>
            Want to see your submissions in Google Sheets, maybe you would like
            to have a message for each new submission in Slack? Well that's all
            possible with forms easy and easy to setup. 1000+ apps available to
            integrate, sky is the limit.
          </p>
          {/* <button className="fifthDiv-btn2">See All Integrations</button> */}
          <div className="sliders">
            <div class="slider1">
              <div class="slide-track1">
                <div class="slide1">
                  <img src={slide1} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide2} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide3} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide4} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide5} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide6} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide1} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide2} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide3} alt="" />
                </div>
              </div>
            </div>

            <div class="slider2">
              <div class="slide-track2">
                <div class="slide2">
                  <img src={slide4} alt="" />
                </div>
                <div class="slide2">
                  <img src={slide2} alt="" />
                </div>
                <div class="slide2">
                  <img src={slide3} alt="" />
                </div>
                <div class="slide2">
                  <img src={slide1} alt="" />
                </div>
                <div class="slide2">
                  <img src={slide4} alt="" />
                </div>
                <div class="slide2">
                  <img src={slide2} alt="" />
                </div>
              </div>
            </div>
            <div class="slider1">
              <div class="slide-track1">
                <div class="slide1">
                  <img src={slide1} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide2} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide3} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide4} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide5} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide6} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide1} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide2} alt="" />
                </div>
                <div class="slide1">
                  <img src={slide3} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sixDiv">
          <div className="sixDiv1">
            {/* <button className="sixDiv1-btn">
              <svg
                width="30"
                height="30"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.9444 21C16.0743 16.3512 17.4455 14.9013 21.2099 13.6364C17.2543 12.3084 16.0207 10.6969 14.9444 6.27273C13.8144 10.9216 12.4432 12.3714 8.67878 13.6364C12.6296 14.9628 13.8703 16.5852 14.9444 21ZM6.59025 11.1818C7.10548 8.76207 8.10097 7.66977 10.0711 7.09091C8.10097 6.51205 7.10548 5.41975 6.59025 3C6.09729 5.31517 5.1683 6.48539 3.10938 7.09091C5.07931 7.67026 6.07502 8.76206 6.59025 11.1818Z"
                  stroke="#780016"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              RELEVANT STUFF BLA BLA
            </button> */}
            <p className="sixDiv1-P">Things, you probably wonder.</p>
            {/* <p className="sixDiv1-P2">
              Lorem ipsum dolor sit amet consectetur. Aliquam diam
            </p> */}
          </div>
          <div className="sixDiv2">
            <ul>
              <li id="1" onClick={openDetail} className="sixDiv2-li">
                <div id="1" onClick={openDetail}>
                  Does form easy blocks spam submissions?
                  <img
                    style={{ float: "right" }}
                    src={lid === "1" ? close : open}
                    alt=""
                    onClick={() => setClick(false)}
                  />
                  <div className={lid === "1" ? "ldetails1" : "ldetails"}>
                    Yes, form easy has advanced in-built systems to block spam
                    submissions.
                  </div>
                </div>
              </li>
              <li id="2" onClick={openDetail} className="sixDiv2-li">
                <div id="2" onClick={openDetail}>
                  What is form endpoint?
                  <img
                    style={{ float: "right" }}
                    src={lid === "2" ? close : open}
                    alt=""
                    onClick={() => setClick(false)}
                  />
                  <div className={lid === "2" ? "ldetails1" : "ldetails"}>
                    A form endpoint or form backend, is a unique URL that will
                    process your forms and performs the necessary actions to
                    fulfill your needings (spam blocking, email sending, file
                    uploads) in a reliable, fast and secure way. hnndes form is
                    the best form endpoint in the industry.
                  </div>
                </div>
              </li>
              <li id="3" onClick={openDetail} className="sixDiv2-li">
                <div id="3" onClick={openDetail}>
                  What is Forms easy?
                  <img
                    style={{ float: "right" }}
                    src={lid === "3" ? close : open}
                    alt=""
                    onClick={() => setClick(false)}
                  />
                  <div className={lid === "3" ? "ldetails1" : "ldetails"}>
                    Form easy is a form API (or endpoint) that allows you to
                    collect submissions from your own designed HTML form without
                    coding any backend, it basically serves as an form backend.
                    By using Hnndes Form you will instantly access the best
                    features such as getting email notifications for each new
                    submission, file uploads, and more
                  </div>
                </div>
              </li>
              <li id="4" onClick={openDetail} className="sixDiv2-li">
                <div id="4" onClick={openDetail}>
                  Can I upload files from my form to Forms easy?
                  <img
                    style={{ float: "right" }}
                    src={lid === "4" ? close : open}
                    alt=""
                    onClick={() => setClick(false)}
                  />
                  <div className={lid === "4" ? "ldetails1" : "ldetails"}>
                    Yes, you can upload files with minimum effort to form easy.
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="sevenDiv">
          <button className="sevenDiv-button">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0547 19C15.0253 19 19.0547 14.9706 19.0547 10C19.0547 5.02944 15.0253 1 10.0547 1C5.08412 1 1.05469 5.02944 1.05469 10C1.05469 11.4397 1.39274 12.8005 1.9938 14.0072C2.44754 14.9182 1.81796 16.1473 1.57805 17.044C1.34535 17.9137 2.141 18.7093 3.0107 18.4766C3.90735 18.2367 5.13649 17.6071 6.04746 18.0609C7.25422 18.6619 8.61497 19 10.0547 19Z"
                stroke="#FEF522"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            RELEVANT STUFF BLA BLA
          </button>
          <p className="sevenDiv-p">See what others are saying</p>
          <div className="sevenDiv-img"></div>
          <p className="sevenDiv-P2">
            “Lorem ipsum dolor sit amet consectetur. Aliquam diam eget faucibus
            neque in ultrices aenean iaculis. Egestas porttitor convallis . “
          </p>
          <p className="sevenDiv-name">Ahmad alali</p>
          <p className="sevenDiv-work">mechanical engineer</p>
          <div style={{ display: "flex", gap: "5px" }}>
            <button className="sevenDiv-btn">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.5547 12.7676H4.55469M4.55469 12.7676L10.1797 18.7095M4.55469 12.7676L10.1797 6.82566"
                  stroke="#1E2330"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button className="sevenDiv-btn">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.55469 12.7676H19.5547M19.5547 12.7676L13.9297 6.82568M19.5547 12.7676L13.9297 18.7095"
                  stroke="#1E2330"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div> */}
      </Box>
      <Footer />
    </Box>
  );
}

export default HomePage;
