import { useCallback, useState } from "react";
import { Flex, InputRadio, InputText, Type } from "ui";

import {
  FlexStyled,
  StyledBox,
  AlignedButton,
  SecondRowItem,
} from "../../../Components";

function GeneralSettings({ edit, selectedForm }) {
  const [error, setError] = useState([]);
  const [checkEnableForm, setCheckEnableForm] = useState(selectedForm?.enabled);

  const [description, setDescription] = useState(selectedForm?.description);
  const [name, setName] = useState(selectedForm?.name);

  const handleInputNameChange = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const handleInputDescriptionChange = useCallback((event) => {
    setDescription(event.target.value);
  }, []);

  const handleEdit = (e) => {
    try {
      e.preventDefault();
      const data = {
        name: name,
        description: description,
        enabled: checkEnableForm,
        // storeData: dataRetention,
      };
      edit(data);
    } catch (e) {
      console.log("error", e);
      if (e.status === 400) setError([e.data.message]);
    }
  };

  return (
    <>
      <FlexStyled>
        <span />
        <StyledBox>
          <Type>Form Name</Type>
          <Type variant="subtitle">Only visible to you</Type>
        </StyledBox>
        <SecondRowItem>
          <InputText
            placeholder={selectedForm?.name}
            size="medium"
            sx={{ mb: 0 }}
            value={name}
            onChange={handleInputNameChange}
          />
        </SecondRowItem>
      </FlexStyled>

      {/* <FlexStyled>
        <StyledBox>
          <Type>Project</Type>
          <Type variant="subtitle">
            group your form in to own our your project
          </Type>
        </StyledBox>

        <Select
          name="Choose a Project"
          data={projects}
          mapData={{ value: "id", label: "name" }}
          defaultValue={projectId}
          size="small"
          style={{width: 250}}
          search={false}
        />
      </FlexStyled> */}

      <FlexStyled style={{ justifyContent: "flex-start" }}>
        <InputRadio
          toggleButton={true}
          checked={checkEnableForm}
          inputProps={{
            checked: checkEnableForm,
            onChange: () => setCheckEnableForm(!checkEnableForm),
          }}
        />
        <StyledBox>
          <Type>Enable form</Type>
          <Type variant="subtitle">
            Form status, disable this to close your form for new messages.
          </Type>
        </StyledBox>
      </FlexStyled>

      {/* <FlexStyled style={{ justifyContent: "flex-start" }}>
          <InputRadio
            toggleButton={true}
            checked={enableFileUpload}
            inputProps={{
              checked: enableFileUpload,
              onChange: () => setEnableFileUpload(!enableFileUpload),
            }}
          />
        <StyledBox>
          <Type>Enable file upload</Type>
          <Type variant="subtitle">
            Change the enctype in the form to allow files to be uploaded
          </Type>
        </StyledBox>
      </FlexStyled> */}

      {/* <FlexStyled style={{ justifyContent: "flex-start" }}>
          <InputRadio
            toggleButton={true}
            checked={dataRetention}
            inputProps={{
              checked: dataRetention,
              onChange: () => setDataRetention(!dataRetention),
            }}
          />
        <StyledBox>
          <Type>Data retention</Type>
          <Type variant="subtitle">
            Save incoming submissions to hnndes form database.
          </Type>
        </StyledBox>
      </FlexStyled> */}

      <FlexStyled>
        <span />
        <StyledBox>
          <Type>Description</Type>
          <Type variant="subtitle">Only visible to you</Type>
        </StyledBox>
        <SecondRowItem>
          <InputText
            type="textarea"
            value={description}
            placeholder={selectedForm?.description}
            sx={{
              mb: "0px",
            }}
            onChange={handleInputDescriptionChange}
          />
        </SecondRowItem>
      </FlexStyled>

      {/* <FlexStyled>
        <StyledBox>
          <Type>Submission archive</Type>
          <Type variant="subtitle">
            Remove submissions from hnndes form database after a period of time
          </Type>
        </StyledBox>
        <Select
          data={["forever", "1 month", "1 year"]}
          name="Forever"
          size="small"
          style={{ width: "250px" }}
          sx={{
            m: "0px",
            pos: "absolute",
            right: "10px",
          }}
        />
      </FlexStyled> */}
      <AlignedButton onClick={(e) => handleEdit(e)}>Save</AlignedButton>
      <Flex
        role="alert"
        sx={{
          d: error.length ? "block" : "none",
          p: 5,
          bgc: "redBg",
          w: "100%",
          border: ["red", 10, "solid", 5, "r"],
          my: 10,
        }}
      >
        {error.map((err) => (
          <Type>{err}</Type>
        ))}
      </Flex>
    </>
  );
}
export default GeneralSettings;
