import { Box, Flex, Grid, Type } from "ui";
import Bar from "./Bar";
import { useProfile } from "../provider";
import { useEffect } from "react";
import { format } from "date-fns";

function Usage() {
  const {
    state: { userLimits, settings },
    fetchLimits,
    fetchSettings,
  } = useProfile();

  useEffect(() => {
    fetchLimits();
    fetchSettings();
  }, [fetchLimits, fetchSettings]);

  if (!userLimits || !settings) return null;

  const pluralize = (count, noun, suffix = "s") =>
    `${count} ${noun}${count !== 1 ? suffix : ""}`;

  const maxForms = settings.maxFormsPerUserPerMonth;
  const totalForms = userLimits.totalFormsCreated;

  const totalSubmit = userLimits.totalSubmissionsSubmitted;
  const maxSubmission = settings.maxSubmissionsPerUserPerMonth;
  const spam = userLimits.totalSpamsDetected;

  const uploaded = userLimits.totalUploadedFilesSize;
  const maxUploads = settings.maxUploadSizePerUserPerMonth;

  const data = [
    {
      name: "Forms",
      statement: `${totalForms} out of ${pluralize(maxForms, "form")}/month`,
      progress: totalForms,
      total: maxForms,
    },
    {
      name: "Submissions",
      statement: `${totalSubmit} out of ${maxSubmission} submissions/month (${pluralize(
        spam,
        "spam"
      )})`,
      progress: totalSubmit,
      total: maxSubmission,
      ofWhich: spam,
    },
    {
      name: "File Uploads",
      statement: `${uploaded} out of ${maxUploads / 1e6} Megabytes/month`,
      progress: uploaded,
      total: maxUploads / 1e6,
    },
  ];

  return (
    <Flex dir="column" gap={20} jc="flex-start" ai="flex-start">
      <Type var="h4" sx={{ fw: 700 }}>
        Usage Between
      </Type>

      {userLimits && userLimits.lastResetDate && (
        <Type sx={{ fs: 16, mb: 30 }}>
          {format(new Date(userLimits.lastResetDate), "MMMM dd, yyyy hh:mm aa")}{" "}
          till Now
        </Type>
      )}

      <Box sx={{ w: "100%" }}>
        {data?.map((item, i) => (
          <Grid cols=".3fr .7fr" key={i + 1} sx={{ w: "100%", mb: 45 }}>
            <Type sx={{mt: -5, fs: 18, fw: 600}}>{item.name}</Type>
            <Bar
              statement={item.statement}
              progress={item.progress}
              total={item.total}
              ofWhich={item.ofWhich}
            />
          </Grid>
        ))}
      </Box>
    </Flex>
  );
}

export default Usage;
