import { useCallback, useEffect, useState } from "react";

import {
  FlexStyled,
  StyledBox,
  AlignedButton,
  SecondRowItem,
} from "../../../Components";

import {
  Button,
  Flex,
  InputRadio,
  InputText,
  Link,
  Select,
  Type,
} from "ui";
import { useProfile } from "views/Account/provider";

function Processing({ edit, selectedForm ,notify}) {
  const [emailNotifications, setEmailNotifications] = useState(
    selectedForm?.enableNotification
  );
  const [autoReply, setAutoReply] = useState(false);
  const {state:{selected:selectedUser},fetchProfile} = useProfile();
  const [redirectUrl, setRedirectUrl] = useState(selectedForm?.redirectURL);

  useEffect(()=>{
    fetchProfile();
    setRedirectUrl(selectedForm?.redirectURL)
  },[selectedForm,fetchProfile])

  const handleRedirectUrlChange = useCallback((event) => {
    setRedirectUrl(event.target.value);
  }, []);

  const validateUrl = (url) => {
    const pattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(:\d{1,5})?([\/?].*)?$/i;
    const isValid = pattern.test(url);
    if (isValid)
      return true
  };
  const handleEdit = (e) => {
    e.preventDefault();
    if (validateUrl(redirectUrl)){
    const data = {
      enableNotification: emailNotifications,
      redirectURL : redirectUrl
    };
    edit(data);
  } else {
    notify(`enter a valid URL`, "info");
  }
  };

  return (
    <>
      <FlexStyled>
        <InputRadio
          toggleButton={true}
          checked={emailNotifications}
          inputProps={{
            checked: emailNotifications,
            onChange: () => setEmailNotifications(!emailNotifications),
          }}
        />
        <StyledBox>
          <Type>Email Notifications</Type>
          <Type variant="subtitle">
            Enable or disable sending notification emails. Submissions will
            still be saved to the archive and dispatched to plugins.
          </Type>
        </StyledBox>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox>
          <Type>Email notification template</Type>
          <Type variant="subtitle">Customize email template</Type>
        </StyledBox>
        <SecondRowItem>
          <Flex dir="column" sx={{ai:["flex-end","_","_","flex-start"]}} gap={10}>
            <Type>Selected Theme</Type>
            <Flex
              ai="flex-end"
              sx={{
                h: 80,
                br: 10,
                w: 100,
                p: 5,
                bgc: "babyBlue",
              }}
            >
              <Type>Formal</Type>
            </Flex>
            <Button size="small" iconName="Customize" >
              Customize
            </Button>
          </Flex>
        </SecondRowItem>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox>
          <Type>Target Email</Type>
          <Type variant="subtitle">
            Where to send submissions. To add a new email address, visit
            the <Link to = '/account'>account page.</Link>
          </Type>
        </StyledBox>
        <SecondRowItem>
          <Select
              data={selectedUser?.emails}
              mapData={{ value: "id", label: "email" }}
              name="target emails"
              size="medium"
              search={false}
              sx={{
                w:"250px",
              }}
            />
        </SecondRowItem>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox>
          <Type>Redirect</Type>
          <Type variant="subtitle">
            After successful submit, where should the user be redirected? 
          </Type>
        </StyledBox>
        <SecondRowItem>
        <InputText
          type="text"
          value={redirectUrl}
          placeholder={selectedForm?.redirectURL?selectedForm?.redirectURL:'enter redirect url'}
          size="medium"
          sx={{
            w: "250px",
            mb: "0px",
          }}
          onChange={handleRedirectUrlChange}
        />
        </SecondRowItem>
      </FlexStyled>

      <FlexStyled style={{ justifyContent: "flex-start" }}>
        <InputRadio
          toggleButton={true}
          checked={autoReply}
          inputProps={{
            checked: autoReply,
            onChange: () => setAutoReply(!autoReply),
          }}
        />
        <StyledBox>
          <Type>Auto reply</Type>
          <Type variant="subtitle">
            Send a customized email to respondents after successful form
            submission
          </Type>
        </StyledBox>
      </FlexStyled>
      <AlignedButton onClick={(e) => handleEdit(e)}>save</AlignedButton>
    </>
  );
}
export default Processing;
