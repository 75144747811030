import styled from "styled-components";
import Label from "./Label";
import Type from "./Type";

const Wrap = styled.div`
  .container {
    display: block;
    position: relative;
    padding-left: 0;
    margin-bottom: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0px;
    margin: 0px;
    background-color: transparent;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  }
  .checkmark {
    position: relative;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    border: ${(p) => p.theme.color.gray500} 1px solid;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
  }
  .container:hover input ~ .checkmark {
    border-width: 2px;
  }
  .container input:active ~ .checkmark {
    border-width: 3px;
  }

  .container input:checked ~ .checkmark {
    border-width: 1px;
  }
  .checkmark:after {
    content: "";
    width: 0;
    height: 0;
    background: transparent;
    border-radius: 100px;
    transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
  }
  .container input:checked ~ div .checkmark:after {
    width: 12px;
    height: 12px;
    background: ${(props) => props.theme.color.secondary};
  }
  /* toggle button class */
  .toggle-button {
    position: relative;
    height: 20px;
    width: 40px;
    background-color: ${(p) => p.theme.color.gray400};
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2px;
    transition: background-color 0.2s ease;
    cursor: pointer;
  }
  .toggle-button:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${(p) => p.theme.color.white};
    transition: transform 0.2s ease;
  }
  .container:hover input ~ .toggle-button {
    background-color: ${(p) => p.theme.color.gray400 + "dd"};
    &:before {
      transform: scale(1.2);
      outline: ${(p) => p.theme.color.gray400}66 1px solid;
    }
  }
  .container:hover input:checked ~ .toggle-button {
    background-color: ${(p) => p.theme.color.primary + "dd"};
    &:before {
      transform: scale(1.2) translateX(16px);
      outline: ${(p) => p.theme.color.primary}66 1px solid;
    }
  }
  .container input:checked ~ .toggle-button {
    background-color: ${(p) => p.theme.color.primary};
  }
  .container input:checked + .toggle-button:before {
    transform: translateX(19px);
  }
`;

function InputRadio({
  toggleButton,
  name,
  label,
  checked,
  inputProps,
  error,
  msg,
  ...props
}) {
  return (
    <Wrap {...props} className={`${error && "error"}`}>
      <Label className="container">
        <span>{label}</span>

        {!toggleButton ? (
          <>
            <input type="radio" {...{ name, checked, ...inputProps }} />
            <div>
              <span className="checkmark"></span>
            </div>
          </>
        ) : (
          <>
            <input type="checkbox" {...{ name, checked, ...inputProps }} />
            <div className="toggle-button"></div>
          </>
        )}
      </Label>
      {msg && <Type sx={{ c: "red" }}>{msg}</Type>}
    </Wrap>
  );
}

export default InputRadio;
