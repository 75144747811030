import { ThemeProvider } from "styled-components";
import AuthProvider from "./auth";
import UtilsProvider from "./utilsProvider";
import theme from "../theme";
import ProfileProvider from "../views/Account/provider";
import FormProvider from "../../src/views/submitionForm/provider";
import ProjectProvider from "../../src/views/project/provider";
import SubmissionProvider from "views/submissions/provider";
import AdminProvider from "views/Admin/provider";
import ApiProvider from "views/api/provider";

export default function Providers({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <UtilsProvider>
        <ApiProvider>
          <AdminProvider>
            <AuthProvider>
              <ProfileProvider>
                <ProjectProvider>
                  <FormProvider>
                    <SubmissionProvider>{children}</SubmissionProvider>
                  </FormProvider>
                </ProjectProvider>
              </ProfileProvider>
            </AuthProvider>
          </AdminProvider>
        </ApiProvider>
      </UtilsProvider>
    </ThemeProvider>
  );
}
