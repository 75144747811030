import { Box, Type } from "ui";
import DayAnalytics from "./DayAnalytics";
import WeekAnalytics from "./WeekAnalytics";
import MonthAnalytics from "./MonthAnalytics";

const Analitics = [DayAnalytics, WeekAnalytics, MonthAnalytics];
function Analytics() {
  return (
    <>
      <Type var="h4" sx={{ mb: 20 }}>
        Overview
      </Type>
      {Analitics.map((Analitic, i) => (
        <Box
          key={i}
          sx={{
            border: ["gray300", 1, "solid", 6, "tlrb"],
            p: [30, 30, 30, 20],
            mb: 70,
          }}
        >
          <Analitic />
        </Box>
      ))}
    </>
  );
}
export default Analytics;
